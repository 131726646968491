import React from "react"
import { Slide, Fade } from "react-reveal"
import swapImage from "./../../images/Animations/Builder.json"
import JSONAnimationHover from "../JSONAnimationHover/JSONAnimationHover"
import "./CVBuilderIntro.scss"

export default function CVBuilderIntro() {
  const showNext = () => {
    document.querySelector(".cv-builder-form").classList.remove("hide")
  }
  return (
    <Slide top>
      <section className="cv-builder-intro bg-white">
        <div className="container container-sm intro-block">
          <div className="row">
            <div className="col-12 col-md-8 col-lg-7">
              <Fade top delay={1000} duration={1000} distance={"100px"}>
                <h1>
                  See how{" "}
                  <span className="yellowpattern">personal details</span> can
                  affect a<br />
                  job application.
                </h1>
              </Fade>
            </div>
            <div className="col-md-4 col-lg-5 right-block">
              <p className="label-tag">THE CV BUILDER</p>
              <JSONAnimationHover
                normal={swapImage}
                className="arrowImg"
                loop={1}
              />
              <p className="para content-maxlength">
                The interactive CV builder shows you the impact details like
                name, age, education and taking a career gap can have during the
                recruitment process.
              </p>
            </div>
          </div>
          <div className="nextArrow" onClick={showNext}>
            <a
              href="#cv-criteria-name"
              className="button"
              data-analytics={`{"event":"form_start",
                "module":"CV builder form",
                "click_text":"Get Started"
              }`}
            >
              <p>
                <span>GET STARTED</span>
              </p>
              <svg
                className="down-arrow-svg"
                width="50"
                height="117"
                viewBox="0 0 50 117"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="left-piece"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.98524 86.4835C7.28588 88.9219 8.71895 90.4996 9.5707 92.8634C10.1807 92.2897 9.50735 93.2133 9.81237 93.3683C10.1174 93.3683 10.6584 93.483 10.0311 93.4658C12.7877 99.7194 16.1257 103.099 18.232 110.133C19.3255 111.395 19.7571 116.834 21.38 115.296C20.8045 115.715 21.2362 116.616 21.5239 115.577C22.0649 115.91 22.3699 116.151 23.8259 114.917C23.5842 114.74 26.4618 112.841 27.279 112.714C27.6876 112.215 29.7824 111.475 29.581 110.792C30.3579 110.098 30.3465 111.366 30.4155 110.351C24.5223 93.5404 18.5256 87.3556 10.0023 72.4043C7.85569 72.4043 -1.44453 75.9614 0.172638 78.2276C3.9307 83.46 2.77967 80.5914 5.98524 86.4835Z"
                  fill="#55DBFF"
                />
                <path
                  className="right-piece"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M36.257 102.462C37.1433 100.58 37.5979 98.6578 38.8065 97.0284C38.231 96.8391 39.4568 96.8047 38.8065 96.185C38.8065 96.185 39.077 96.4317 38.9849 96.426C39.7862 95.567 40.3632 94.5247 40.6653 93.391C42.6306 89.2492 45.387 85.529 48.78 82.4386C48.5901 82.2779 48.5268 81.8648 48.6246 82.1804C49.3613 81.6067 50.6965 80.4592 49.3555 80.1953C49.8965 80.4764 50.3626 79.8338 49.5109 79.9773C49.0965 79.6273 50.2706 79.524 49.2117 79.2085C49.6145 79.1626 48.3772 78.4684 48.1182 78.3594C48.0376 78.652 42.7142 76.4259 42.2537 75.8005C41.7473 75.6743 41.4826 75.4047 41.0279 75.8579C40.228 75.5137 41.1545 75.0088 40.3776 75.3473C36.5177 78.8688 33.3128 83.0428 30.9105 87.6767C26.646 96.5006 21.0694 104.975 20.2637 114.963C21.7657 116.346 28.7236 117.074 31.1983 116.977C32.3936 111.982 34.0884 107.119 36.257 102.462Z"
                  fill="#55DBFF"
                />
                <path
                  d="M21.1612 115.491C21.2411 115.445 21.3146 115.389 21.3799 115.325C21.3138 115.378 21.2573 115.442 21.2131 115.514L21.5181 115.634C21.5181 115.634 21.5181 115.634 21.5181 115.606L21.6447 115.686L22.2202 115.875C22.554 115.875 23.0087 115.629 23.8086 114.946C23.5669 114.768 26.4444 112.869 27.2617 112.743C27.6703 112.244 29.7651 111.504 29.5637 110.821C30.3406 110.127 30.3291 111.395 30.3981 110.379C28.9907 106.307 27.332 102.325 25.4315 98.457C22.8763 103.689 20.7296 109.065 20.252 114.975C20.5244 115.196 20.8316 115.37 21.1612 115.491Z"
                  fill="#1DC6FF"
                />
                <path
                  d="M25.2245 43.0066C24.4591 43.0582 24.0102 42.9148 23.3541 43.0066C15.297 43.15 20.7298 53.2304 19.6651 57.5793C19.4177 61.3831 26.7496 58.8357 28.6545 57.5506C29.4545 56.6498 29.1207 54.986 29.7078 54.4065C29.8113 54.332 29.7998 54.8311 29.8746 54.8082C29.9494 54.7852 30.1393 54.2344 30.03 54.2344C29.1437 50.3675 31.2674 41.5092 25.2245 43.0066ZM26.3755 43.0066C26.4533 43.0638 26.5361 43.1137 26.623 43.1558C26.5299 43.1261 26.4451 43.075 26.3755 43.0066Z"
                  fill="#55DBFF"
                />
                <path
                  d="M24.0735 21.5664C20.9025 21.1706 18.658 22.5131 19.2277 25.9095C20.0507 26.9193 18.2839 31.6468 19.9414 31.8246C17.248 36.8562 22.9858 38.6118 26.6575 36.1792C26.6575 36.076 26.6575 35.9727 26.6575 35.8694C30.2142 35.2498 29.9034 32.1517 29.5351 29.4552C29.4545 24.9571 29.5351 20.6944 24.085 21.5664H24.0735ZM26.6691 21.8648C26.7517 21.9173 26.8382 21.9633 26.928 22.0025C26.8312 21.9796 26.742 21.9322 26.6691 21.8648Z"
                  fill="#55DBFF"
                />
                <path
                  className="first-line"
                  d="M22.2778 0.125997C17.0522 -0.315772 19.3312 4.98546 19.475 8.21554C18.8995 16.0985 21.5123 17.1198 29.4141 13.7864C32.0326 11.3596 28.5393 7.28036 29.2759 4.77318C28.7004 2.11683 29.1435 -0.436254 25.6387 0.18337L26.007 0.326802C24.7953 -0.0211377 23.52 -0.0898041 22.2778 0.125997Z"
                  fill="#55DBFF"
                />
                <path
                  d="M19.8376 65.7778C20.3614 68.9161 19.5153 72.8289 20.5858 75.7778C20.6491 77.3269 19.8146 80.6602 21.3972 81.6012C21.3972 77.3613 28.8443 82.3929 30.145 80.8725C31.4456 79.3521 30.7895 76.6442 30.6342 74.3837C30.3464 70.3045 31.1349 64.3836 24.9654 65.4336C23.2718 64.916 21.4463 65.0386 19.8376 65.7778Z"
                  fill="#55DBFF"
                />
              </svg>
            </a>
          </div>
        </div>
      </section>
    </Slide>
  )
}
