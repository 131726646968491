import React, { useEffect, useState, useRef } from "react"
import { graphql } from "gatsby"
import { Slide, Fade } from "react-reveal"
import { Markup } from "interweave"
import sendcvimg from "../../images/send-cv.png"
import CVBuilderResult from "../CVBuilderResult/CVBuilderResult"
import swapBtn from "./../../images/Animations/swap_btn.json"

import Question1 from './../../images/Animations/Question_PictureFrame.json'
import Question2 from './../../images/Animations/Question_Horizontal Squiggle.json'
import Question3 from './../../images/Animations/Question_Z.json'
import Question4 from './../../images/Animations/Question_Vertical Squiggle.json'
import "./Cvswapout.scss"

import TextHighlights from "./../global/TextHighlights/TextHighlights"
import JSONAnimationHover from "../JSONAnimationHover/JSONAnimationHover"

export const cvSwapoutFragment = graphql`
  fragment cvSwapoutData on PrismicBlindcvCvReviewStages {
    data {
      title
      review_stages {
        stage {
          document {
            ... on PrismicBlindcvReviewStage {
              data {
                type
                label
                description
                background_colour
                help_text
                next_button_label
                select_placeholder
                values {
                  value_mapping {
                    document {
                      ... on PrismicBlindcvReviewValueMapping {
                        data {
                          source {
                            html
                            text
                          }
                          summary {
                            html
                            text
                          }
                          type
                          value
                          score
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      final_summary {
        document {
          ... on PrismicBlindcvFinalReviewSummary {
            id
            data {
              title
              top_label
              description {
                text
                html
              }
              button_label {
                text
                html
              }
              right_title {
                text
                html
              }
              right_tool_download {
                text
                html
              }
              right_download_link
            }
          }
        }
      }
    }
  }
`




const  QuestionImage = ({number}) => {
  const Questions = [Question1, Question2, Question3, Question4];
  const [pause, setPause] = useState(true);
  const highlightRef = useRef();


  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if(entries[0].isIntersecting) setPause(false);
    });
    
    observer.observe(highlightRef.current);

    return () => {
      observer.disconnect()
    }
  }, [])
  return (
      <span ref={highlightRef} className="question-highlight-animate">
        {(!pause)?<JSONAnimationHover normal={Questions[number]} className={`arrowImg item-img item-${number}-img`} hasHover={true} waitToPlay={1500} loop={0} />:<></> }
      </span>
    );
}

const Cvswapout = ({ data }) => {
  const [currentActiveStage, setCurrentActiveStage] = useState(0)
  const [selectedNameStage, setSelectedNameStage] = useState("Name")
  const [selectedAgeStage, setSelectedAgeStage] = useState("Age")
  const [selectedEduStage, setSelectedEduStage] = useState("Education")
  const [selectedWorkStage, setSelectedWorkStage] = useState("Work History")
  const [score, setScore] = useState(0)
  const [btnActive, setBtnActive] = useState(0)
  const [btnActive1, setBtnActive1] = useState([])

  const stages = data.review_stages
  const summary = data.final_summary.document.data
  let allDisableStages = []
  let disableStages
  const isBrowser = typeof window !== "undefined"

  const getScore = (stage, value) => {
    for (let n = 0; n < stages.length; n++) {
      if (stage === stages[n].stage.document.data.type) {
        for (let m = 0; m < stages[n].stage.document.data.values.length; m++) {
          if (
            value ===
            stages[n].stage.document.data.values[m].value_mapping.document.data
              .value
          ) {
            return parseInt(
              stages[n].stage.document.data.values[m].value_mapping.document
                .data.score
            )
          }
        }
        return 0
      }
    }
    return 0
  }

  useEffect(() => {
    setScore(
      getScore("Name", selectedNameStage) +
        getScore("Age", selectedAgeStage) +
        getScore("Education", selectedEduStage) +
        getScore("Work History", selectedWorkStage)
    )
  }, [
    getScore,
    setScore,
    selectedNameStage,
    selectedAgeStage,
    selectedEduStage,
    selectedWorkStage,
  ])

  function handleActivateNextStage(value, nextid, e) {
    e.preventDefault()
    setCurrentActiveStage(value)
    if (isBrowser) {
      var nextStageEl = document.getElementById(nextid).nextSibling

      if (nextStageEl)
        setTimeout(function () {
          nextStageEl.scrollIntoView()
        }, 300)
    }
    if (nextid === "cv-criteria-work-history") {
      document.querySelector(".next-section").classList.remove("hide")
    }
  }

  function handleSwapoutChange(ev, value, name) {

    if (!name || !value) return

    const hideAllResults = elem => elem.classList.add("hide")

    if (isBrowser) {
      var allWrapperEl = document.querySelectorAll(
        ".result-wrap-" + createStageResultId(name)
      )
      var currentWrapperEl = document.getElementById(
        "result-" + createStageResultId(value)
      )
      var currentStageWrapperEl = document.getElementById(
        "cv-criteria-result-" + createStageResultId(name)
      )

      allWrapperEl.forEach(hideAllResults)
      if (name !== value) {
        currentWrapperEl.classList.remove("hide")
        currentStageWrapperEl.parentElement.classList.remove("hide")
        currentStageWrapperEl.classList.remove("hide")
        currentStageWrapperEl.scrollIntoView()
      }
      if (name === value) currentStageWrapperEl.classList.add("hide")
    }
  }

  function handleChange(event, i) {
    const { value } = event.target ? event.target : ""
    const { name } = event.target ? event.target : ""

    if (!name || !value) return

    if (isBrowser) {
      var currentStageWrapperEl = document.getElementById(
        "cv-criteria-result-" + createStageResultId(name)
      )
      let nxtButton =
        currentStageWrapperEl.parentElement.parentElement.querySelector(
          ".btn-next"
        )
      nxtButton.classList.remove("inactive")
      nxtButton.classList.add("active")

      setBtnActive(1)
      setBtnActive1((e) => {
        e.push(i);
        return e;
      })
      
      if (name !== value) {
        if (name === "Name") setSelectedNameStage(value)

        if (name === "Age") setSelectedAgeStage(value)

        if (name === "Education") setSelectedEduStage(value)

        if (name === "Work History") setSelectedWorkStage(value)
      }
      if (name === value) currentStageWrapperEl.classList.add("hide")
    }
  }

  const createStageResultId = stageValue => {
    let lowerCaseText = stageValue.toLowerCase()
    let stageResultId = lowerCaseText.replace(/\s/g, "-")

    return stageResultId
  }

  const getCurrentStageState = type => {
    switch (type) {
      case "Name":
        return selectedNameStage
      case "Age":
        return selectedAgeStage
      case "Education":
        return selectedEduStage
      case "Work History":
        return selectedWorkStage
      default:
        return
    }
  }

  const EmptyBlocks = ({ blocks }) => {
    const blocksHtml = []

    for (let i = 0; i < blocks; i++) {
      blocksHtml.push(
        <span key={i + 1} tabIndex="-1" aria-label={i + 1}></span>
      )
    }
    return <div className="empty-block">{blocksHtml}</div>
  }

  const StageIndicators = ({ stages, currentindex }) => {
    const indicatorsHtml = []

    for (let i = 0; i < stages.length; i++) {
      let activClass = currentindex < i ? "card-data show" : "card-data hide"

      if (!stages[i]) return

      indicatorsHtml.push(
        <Fade
          left
          delay={600 * i}
          duration={1000}
          distance={"50px"}
          key={i + 1}
        >
          <div
            className={activClass}
            key={i + 1}
            tabIndex="-1"
            aria-label={i + 1}
          >
            <div className="block-header">
              <span>{stages[i].stage.document.data.type}</span>
            </div>
            <div className="border-bottom-dashed right"></div>
          </div>
        </Fade>
      )
    }
    return indicatorsHtml
  }

  const SelectOptions = ({ options, placeholder }) => {
    let currentStageOptions =
      options.length > 0 &&
      options.map((stage_value, i) => {
        return (
          <React.Fragment key={i}>
            {i === 0 && (
              <option
                key={i}
                value={stage_value.value_mapping.document.data.type}
              >
                {placeholder}
              </option>
            )}
            <option
              key={i + 1}
              value={stage_value.value_mapping.document.data.value}
            >
              {stage_value.value_mapping.document.data.value}
            </option>
          </React.Fragment>
        )
      })

    return currentStageOptions
  }

  let stagesList =
    stages.length > 0 &&
    stages.map((stage, i) => {

      

      let currentStageResults =
        stage.stage.document.data.values.length > 0 &&
        stage.stage.document.data.values.map((result, r) => {
          return (
            <div
              key={r}
              className={
                `container answer-block answer-block-${createStageResultId(
                  stage.stage.document.data.type
                )}-theme-${r + 1}
             result-wrap result-wrap-` +
                createStageResultId(stage.stage.document.data.type) +
                ` hide display-content`
              }
              id={
                `result-` +
                createStageResultId(result.value_mapping.document.data.value)
              }
            >
              <div className="row">
                <div className="col-12 col-md-12 col-lg-7">
                  <div className="card-data">
                    <Fade delay={500}>
                      <div className="block-header">
                        <span>{stage.stage.document.data.type}</span>
                      </div>
                      <div className="border-bottom-dashed right">
                        <div className="block-data">
                          <span className="">
                            {result.value_mapping.document.data.value}
                          </span>
                        </div>
                      </div>
                    </Fade>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-center align-items-center intro">
                <div className="col-12 col-md-8 col-lg-7">
                  <Fade bottom delay={300} duration={500} distance={"100px"}>
                    <TextHighlights
                      content={result.value_mapping.document.data.summary.text}
                      cssClass=""
                      waitToPlay="3000"
                    />
                  </Fade>
                </div>
                <div className="col-md-4 col-lg-4 right-block mx-auto">
                  <Fade bottom delay={1000} duration={1000} distance={"100px"}>
                    <Markup
                      content={result.value_mapping.document.data.source.text}
                    />
                  </Fade>
                </div>
              </div>
            </div>
          )
        })

      const resIdPrefix =
        `cv-criteria-result-` +
        createStageResultId(stage.stage.document.data.type)
      var idPrefix =
        `cv-criteria-` + createStageResultId(stage.stage.document.data.type)

      const currentStageState = {
        stage: stage.stage.document.data,
        options: stage.stage.document.data.values,
      }

      if (i === stages.length - 1) allDisableStages.push(currentStageState)

      const DisabledStages = (data) => {
        let disableSelectValue = getCurrentStageState(data.disableStage.stage.type)
        return             <div
              
              className={`card-data disabled-` + data.disableStage.stage.type}
            >
              <div className="block-header">
                <span>{data.disableStage.stage.type}</span>
              </div>
              <div className="block-data">{disableSelectValue}</div>
            </div>
      }

      disableStages =
        allDisableStages.length > 0 &&
        allDisableStages.map((disableStage, s) => {
          // let disableSelectValue = getCurrentStageState(disableStage.stage.type)

          return (
            <DisabledStages disableStage={disableStage} />
          )
        })
      if (i !== stages.length - 1) allDisableStages.push(currentStageState)

      let selectValue = getCurrentStageState(stage.stage.document.data.type)
      return (
        <div
          key={i}
          className={`cv-criteria  ${createStageResultId(
            stage.stage.document.data.type
          )}`}
          id={idPrefix}
        >
          <div
            className={`container card-padding ${
              currentActiveStage < i ? "hide" : ""
            }`}
          >
            <div className={`row `}>
              <div className={`col-12 col-md-12 col-lg-8`}>
                <Slide bottom>
                  <div className="card">
                    <Fade top delay={1000} duration={1000} distance={"100px"}>
                      <div className="card-body">
                        <div className="card-header">
                          <p>Curriculum vitae </p>
                        </div>
                        <div className="card-details">
                          {disableStages}
                          <div className="card-data">
                            <div className="block-header">
                              <span>{stage.stage.document.data.type}</span>
                            </div>
                            <div
                              className={
                                `bg-` +
                                createStageResultId(
                                  stage.stage.document.data.type
                                )
                              }
                            ></div>
                          </div>
                          <EmptyBlocks blocks={stages.length - 1 - i} />
                          <h2>
                            <span className={`question-${i+1}`}>
                              {stage.stage.document.data.label}
                            </span>
                            <QuestionImage number = {i} />
                          </h2>
                          <div className="form-group">
                            <form className="w-75 w-sm-100">
                              <select
                                value={selectValue}
                                onChange={(e) => handleChange(e, i)}
                                className="form-select"
                                aria-label={stage.stage.document.data.type}
                                name={stage.stage.document.data.type}
                                id={stage.stage.document.data.type}
                              >
                                <SelectOptions
                                  options={stage.stage.document.data.values}
                                  placeholder={
                                    stage.stage.document.data.select_placeholder
                                  }
                                />
                              </select>
                            </form>
                            <p>{stage.stage.document.data.description}</p>
                          </div>
                        </div>
                        <div className="card-footer">
                          <button
                            className="btn btn-next inactive"
                            onClick={ev =>
                              handleSwapoutChange(
                                ev,
                                selectValue,
                                stage.stage.document.data.type
                              )
                            }
                            data-analytics={`{"event":"form_step_interaction",
                              "module":"CV builder form",
                              "click_text":"Swap out",
                              "step_name":" ${stage.stage.document.data.label.replace(
                                /(?:\r\n|\r|\n)/g,
                                " "
                              )}",
                              "step_number":"${i + 1}"

                            }`}
                          >
                            <span>SWAP OUT</span>
                            {btnActive1.includes(i) ? (
                              <JSONAnimationHover
                                normal={swapBtn}
                                className="swapArrowImg"
                                loop={1}
                              />
                            ) : (
                              ""
                            )}
                          </button>
                        </div>
                      </div>
                    </Fade>
                  </div>
                </Slide>
              </div>
              <div className="col-12 col-md-8 col-lg-4 right-block">
                <div className="card-details">
                  <StageIndicators currentindex={i} stages={stages} />
                </div>
              </div>
            </div>
          </div>
          <div className="min-height-wrapper hide">
            <section
              className="cv-builder-answer bg-white cv-criteria-result hide"
              id={resIdPrefix}
            >
              {currentStageResults}
              <div className={`next-stage nextArrow`}>
                <button
                  onClick={e => handleActivateNextStage(i + 1, idPrefix, e)}
                  className="button"
                  data-analytics={`{"event":"${
                    i >= 3 ? "form_submit" : "form_step_interaction"
                  }",
                    "module":"CV builder form",
                    "step_name":" ${stage.stage.document.data.next_button_label.replace(
                      /(?:\r\n|\r|\n)/g,
                      " "
                    )} - anchor link",
                    "click_text":"${stage.stage.document.data.next_button_label.replace(
                      /(?:\r\n|\r|\n)/g,
                      " "
                    )}",
                    "step_number":"${i + 2}"

                  }`}
                >
                  <p>
                    <span>{stage.stage.document.data.next_button_label}</span>
                  </p>
                  <svg
                    className="down-arrow-svg"
                    width="50"
                    height="117"
                    viewBox="0 0 50 117"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="left-piece"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.98524 86.4835C7.28588 88.9219 8.71895 90.4996 9.5707 92.8634C10.1807 92.2897 9.50735 93.2133 9.81237 93.3683C10.1174 93.3683 10.6584 93.483 10.0311 93.4658C12.7877 99.7194 16.1257 103.099 18.232 110.133C19.3255 111.395 19.7571 116.834 21.38 115.296C20.8045 115.715 21.2362 116.616 21.5239 115.577C22.0649 115.91 22.3699 116.151 23.8259 114.917C23.5842 114.74 26.4618 112.841 27.279 112.714C27.6876 112.215 29.7824 111.475 29.581 110.792C30.3579 110.098 30.3465 111.366 30.4155 110.351C24.5223 93.5404 18.5256 87.3556 10.0023 72.4043C7.85569 72.4043 -1.44453 75.9614 0.172638 78.2276C3.9307 83.46 2.77967 80.5914 5.98524 86.4835Z"
                      fill="#55DBFF"
                    />
                    <path
                      className="right-piece"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M36.257 102.462C37.1433 100.58 37.5979 98.6578 38.8065 97.0284C38.231 96.8391 39.4568 96.8047 38.8065 96.185C38.8065 96.185 39.077 96.4317 38.9849 96.426C39.7862 95.567 40.3632 94.5247 40.6653 93.391C42.6306 89.2492 45.387 85.529 48.78 82.4386C48.5901 82.2779 48.5268 81.8648 48.6246 82.1804C49.3613 81.6067 50.6965 80.4592 49.3555 80.1953C49.8965 80.4764 50.3626 79.8338 49.5109 79.9773C49.0965 79.6273 50.2706 79.524 49.2117 79.2085C49.6145 79.1626 48.3772 78.4684 48.1182 78.3594C48.0376 78.652 42.7142 76.4259 42.2537 75.8005C41.7473 75.6743 41.4826 75.4047 41.0279 75.8579C40.228 75.5137 41.1545 75.0088 40.3776 75.3473C36.5177 78.8688 33.3128 83.0428 30.9105 87.6767C26.646 96.5006 21.0694 104.975 20.2637 114.963C21.7657 116.346 28.7236 117.074 31.1983 116.977C32.3936 111.982 34.0884 107.119 36.257 102.462Z"
                      fill="#55DBFF"
                    />
                    <path
                      d="M21.1612 115.491C21.2411 115.445 21.3146 115.389 21.3799 115.325C21.3138 115.378 21.2573 115.442 21.2131 115.514L21.5181 115.634C21.5181 115.634 21.5181 115.634 21.5181 115.606L21.6447 115.686L22.2202 115.875C22.554 115.875 23.0087 115.629 23.8086 114.946C23.5669 114.768 26.4444 112.869 27.2617 112.743C27.6703 112.244 29.7651 111.504 29.5637 110.821C30.3406 110.127 30.3291 111.395 30.3981 110.379C28.9907 106.307 27.332 102.325 25.4315 98.457C22.8763 103.689 20.7296 109.065 20.252 114.975C20.5244 115.196 20.8316 115.37 21.1612 115.491Z"
                      fill="#1DC6FF"
                    />
                    <path
                      d="M25.2245 43.0066C24.4591 43.0582 24.0102 42.9148 23.3541 43.0066C15.297 43.15 20.7298 53.2304 19.6651 57.5793C19.4177 61.3831 26.7496 58.8357 28.6545 57.5506C29.4545 56.6498 29.1207 54.986 29.7078 54.4065C29.8113 54.332 29.7998 54.8311 29.8746 54.8082C29.9494 54.7852 30.1393 54.2344 30.03 54.2344C29.1437 50.3675 31.2674 41.5092 25.2245 43.0066ZM26.3755 43.0066C26.4533 43.0638 26.5361 43.1137 26.623 43.1558C26.5299 43.1261 26.4451 43.075 26.3755 43.0066Z"
                      fill="#55DBFF"
                    />
                    <path
                      d="M24.0735 21.5664C20.9025 21.1706 18.658 22.5131 19.2277 25.9095C20.0507 26.9193 18.2839 31.6468 19.9414 31.8246C17.248 36.8562 22.9858 38.6118 26.6575 36.1792C26.6575 36.076 26.6575 35.9727 26.6575 35.8694C30.2142 35.2498 29.9034 32.1517 29.5351 29.4552C29.4545 24.9571 29.5351 20.6944 24.085 21.5664H24.0735ZM26.6691 21.8648C26.7517 21.9173 26.8382 21.9633 26.928 22.0025C26.8312 21.9796 26.742 21.9322 26.6691 21.8648Z"
                      fill="#55DBFF"
                    />
                    <path
                      className="first-line"
                      d="M22.2778 0.125997C17.0522 -0.315772 19.3312 4.98546 19.475 8.21554C18.8995 16.0985 21.5123 17.1198 29.4141 13.7864C32.0326 11.3596 28.5393 7.28036 29.2759 4.77318C28.7004 2.11683 29.1435 -0.436254 25.6387 0.18337L26.007 0.326802C24.7953 -0.0211377 23.52 -0.0898041 22.2778 0.125997Z"
                      fill="#55DBFF"
                    />
                    <path
                      d="M19.8376 65.7778C20.3614 68.9161 19.5153 72.8289 20.5858 75.7778C20.6491 77.3269 19.8146 80.6602 21.3972 81.6012C21.3972 77.3613 28.8443 82.3929 30.145 80.8725C31.4456 79.3521 30.7895 76.6442 30.6342 74.3837C30.3464 70.3045 31.1349 64.3836 24.9654 65.4336C23.2718 64.916 21.4463 65.0386 19.8376 65.7778Z"
                      fill="#55DBFF"
                    />
                  </svg>
                  <svg
                    className="page-down-img"
                    width="64"
                    height="147"
                    viewBox="0 0 64 147"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <path
                        d="M60.3055 95.0753C52.6541 89.1497 45.2151 82.2365 34.2829 83.7884C29.1801 83.1733 25.7876 83.1281 21.322 86.2207C0.843781 90.2558 -7.74097 124.782 8.46722 137.999C19.0025 145.065 36.4124 152.108 46.9589 141.78C53.1069 138.394 59.92 133.846 61.9767 126.487C63.6702 120.437 66.2131 98.2412 60.3055 95.0753ZM52.9224 119.906C52.5312 127.807 46.305 132.886 39.5087 134.974C30.5663 140.014 19.4721 135.415 12.564 128.862C8.0928 124.957 10.4122 112.655 11.4462 107.141C20.9532 83.4216 62.1778 92.6204 52.9224 119.884V119.906Z"
                        fill="#55DBFF"
                      />
                      <path
                        d="M32.433 42.303C31.6897 42.3595 31.2537 42.2127 30.6166 42.303C22.7919 42.4441 28.0737 52.3596 27.0342 56.6374C26.7994 60.3789 33.9143 57.8733 35.7643 56.6091C36.5468 55.7231 36.2168 54.0865 36.7869 53.5165C36.8875 53.4432 36.8763 53.9342 36.949 53.9116C37.0216 53.889 37.2061 53.3472 37.0999 53.3472C36.2727 49.521 38.3294 40.8301 32.433 42.303ZM33.523 42.303C33.5984 42.3593 33.679 42.4084 33.7634 42.4498C33.673 42.4206 33.5905 42.3704 33.523 42.303Z"
                        fill="#55DBFF"
                      />
                      <path
                        d="M31.3489 21.2137C28.2638 20.8243 26.0842 22.1449 26.6375 25.4858C27.4368 26.479 25.7208 31.1292 27.3304 31.3042C24.7148 36.2534 30.287 37.9803 33.8528 35.5875C33.8528 35.4859 33.8529 35.3843 33.8809 35.2828C37.3349 34.6733 37.0331 31.6258 36.6754 28.9734C36.5916 24.549 36.6419 20.3615 31.377 21.2137H31.3489ZM33.864 21.5072C33.9453 21.5618 34.0314 21.609 34.121 21.6482C34.0254 21.6228 33.9372 21.5744 33.864 21.5072Z"
                        fill="#55DBFF"
                      />
                      <path
                        className="first-line"
                        d="M29.5995 0.123983C24.5247 -0.310561 26.7379 4.90397 26.8776 8.08122C26.3187 15.8353 28.8563 16.8398 36.5356 13.561C39.0786 11.1738 35.6805 7.16134 36.3959 4.69516C35.837 2.08226 36.2672 -0.429069 32.8635 0.180421L33.2212 0.321507C32.0444 -0.0207419 30.806 -0.0882885 29.5995 0.123983Z"
                        fill="#55DBFF"
                      />
                      <path
                        d="M27.5259 63.2237C28.0345 66.3163 27.2184 70.1651 28.2523 73.0658C28.3138 74.5895 27.5036 77.8627 29.0462 78.7883C29.0462 74.6178 36.2727 79.5727 37.5414 78.0772C38.8101 76.5817 38.1618 73.9123 38.0165 71.6945C37.7315 67.6763 38.4971 61.858 32.5113 62.8907C30.8652 62.3811 29.0911 62.4996 27.5259 63.2237Z"
                        fill="#55DBFF"
                      />
                    </g>
                  </svg>
                  <img className="send-cv-img" alt="" src={sendcvimg}></img>
                </button>
              </div>
            </section>
          </div>
        </div>
      )
    })

  return (
    <section className="cv-builder-form hide">
      {stagesList}

      <div className={`cv-criteria`} id={`cv-criteria-summary`}>
        <div
          className={`cv-criteria-select summary
          ${currentActiveStage < stages.length ? "hide" : ""}`}
        >
          <CVBuilderResult
            stages={disableStages}
            summary={summary}
            score={score}
          />
        </div>
      </div>
    </section>
  )
}

export default Cvswapout