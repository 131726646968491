import React from "react"
import { graphql } from "gatsby"
import NavigationBar from "../components/global/NavigationBar/NavigationBar"
import CVBuilderIntro from "../components/CVBuilderIntro/CVBuilderIntro"
import Cvswapout from "../components/Cvswapout/Cvswapout"
import SiteFooter from "../components/global/SiteFooter/SiteFooter"

import Layout from "./../components/Layout/Layout"

export const cvBuilderPage = graphql`
  query cvBuilderPage {
    prismicBlindcvCvBuilder {
      data {
        analytics {
          document {
            ... on PrismicBlindcvPageAnalytics {
              data {
                event
                page_title
                page_type
              }
            }
          }
        }
        cv_review {
          document {
            ...cvSwapoutData
          }
        }
      }
    }
  }
`

const CvBuilderPage = ({ data }) => {
  return (
    <div>
      <Layout
        pageViewAnalyticsData={
          data.prismicBlindcvCvBuilder.data.analytics.document.data
        }
      >
        <NavigationBar />
        <div className="page-content">
          <CVBuilderIntro />
          <Cvswapout
            data={data.prismicBlindcvCvBuilder.data.cv_review.document.data}
          />
          <div className="next-section hide">
            <SiteFooter />
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default CvBuilderPage
