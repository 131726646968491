import React from "react"
import { Slide, Fade } from "react-reveal"
import { Markup } from "interweave"
import "./CVBuilderResult.scss"
import Subtractblueright from "./../../images/Subtractrightblue.png"
import Subtractblueleft from "./../../images/Subtractleftblue.png"
import makeCV from "./../../images/result-no.gif"
import makeCVSuccess from "./../../images/result-yes.gif"
import JSONAnimationHover from "../JSONAnimationHover/JSONAnimationHover"
import Download from "./../../images/Animations/Download.json"

const CVBuilderResult = ({ stages, summary, score }) => {
  let finalSummary = summary.description.text.replace(
    "very likely",
    score >= 80
      ? "much more likely"
      : score >= 50
      ? "more likely"
      : score >= 20
      ? "less likely"
      : "much less likely"
  )
  return (
    <section className="cv-builder-result">
      <div className="container container-sm">
        <div className="row summary-content">
          <div className="col-12 col-md-12 col-lg-8 card-content">
            <Slide bottom delay={500} duration={1000}>
              <div className="card">
                <div className="card-body">
                  <div className="card-header">
                    <Fade left delay={800} duration={1000} distance={"100px"}>
                      <p>{summary.top_label}</p>
                    </Fade>
                  </div>
                  <div className="card-details">
                    <Fade left delay={1200} duration={1000} distance={"100px"}>
                      {stages}
                    </Fade>
                  </div>
                  <div className="block-img">
                    <Fade
                      bottom
                      delay={1600}
                      duration={1000}
                      distance={"100px"}
                    >
                      {score >= 50 ? (
                        <img
                          src={makeCVSuccess}
                          className=""
                          alt="make CV"
                        ></img>
                      ) : (
                        <img src={makeCV} className="" alt="make CV"></img>
                      )}
                    </Fade>
                  </div>
                  <div className="card-footer">
                    <div
                      className={
                        score >= 50 ? "greenAsSelected" : "pinkAsDefault"
                      }
                    >
                      <Fade
                        bottom
                        delay={2000}
                        duration={1000}
                        distance={"100px"}
                      >
                        <Markup content={finalSummary} />
                        <Markup content={summary.button_label.text} />
                      </Fade>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
          </div>

          <div className="col-12 col-lg-4 p-0">
            <Slide bottom delay={500} duration={1000}>
              <div className="card-2">
                <a href={summary.right_download_link} className="card-hover">
                  <div className="row">
                    <div className="col-6 col-lg-12 col-md-6 h3-set">
                      <img
                        src={Subtractblueright}
                        alt="Right arrow"
                        className="eclipsecut left"
                      />
                      <img
                        src={Subtractblueleft}
                        alt="Left arrow"
                        className="eclipsecut right"
                      />
                      <h2>
                        <Fade
                          bottom
                          delay={800}
                          duration={1000}
                          distance={"100px"}
                        >
                          <Markup content={summary.right_title.text} />
                        </Fade>
                      </h2>
                    </div>

                    <div className="col-6 col-lg-12 col-md-6">
                      <Fade
                        bottom
                        delay={1200}
                        duration={1000}
                        distance={"100px"}
                      >
                        <div className="download-cta">
                          <JSONAnimationHover
                            normal={Download}
                            className={`arrowImg item-img download-arrow`}
                            hasHover={true}
                            loop={1}
                          />
                          <Markup content={summary.right_tool_download.text} />
                        </div>
                      </Fade>
                    </div>
                  </div>
                </a>
              </div>
            </Slide>
          </div>
        </div>
      </div>
    </section>
  )
}
export default CVBuilderResult
